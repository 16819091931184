
import PropTypes from "prop-types"
import React from "react"
import "../styles/global.scss"
import UIkit from 'uikit'
import Seo from "../components/seo/seo.js"
import FooterLanding from "../components/footer-landing/footerLanding.js"



const LandingPageLayout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta, canonical}) => {

    return(
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription} meta={meta} canonical={canonical}></Seo>

            <main className="landing-page-style">{children}</main>

            {/* footer section */}
            <FooterLanding disclaimer={footerDisclaimer}></FooterLanding> 
        </>
    )
    
}

LandingPageLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LandingPageLayout
